<template>
  <div>
    <userAccount notpopup v-if="user" />
    <div v-else>
      <!-- <registerModal
        v-if="showRegModal"
        @close="closeReg"
        @login="
          showRegModal = false;
          showLoginModal = true;
        "
        @on-click="openLoginModal"
      /> -->
      <loginModal @close="closeLogin" v-if="showLoginModal" />
    </div>
  </div>
</template>

<script>
import userAccount from "./components/userAccount";
import loginModal from "./components/loginModal";
// import registerModal from "./components/registerModal";
import { mapState } from "vuex";

export default {
  components: {
    userAccount,
    loginModal,
    // registerModal,
  },
  data() {
    return {
      showRegModal: true,
      showLoginModal: true,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  methods: {
    closeLogin() {
      if (this.user) {
        this.showLoginModal = false;
      } else {
        this.$router.push("/");
      }
    },
    closeReg() {
      if (!this.user) {
        this.$router.push("/");
      }
      this.showRegModal = false;
    },
  },
  created() {},
};
</script>

<style></style>
