<template>
  <div>
    <div :key="item" v-for="item of userInfo">
      <div class="mt-4 bold" v-if="item.isEmail && disableEmail">
        {{ item.value }}
      </div>
      <inputTextField
        :id="item.id"
        v-else-if="
          item.type == 'textfield' &&
            !(item.disable && shippingMethodcode == 'pats')
        "
        :isRequired="notRequired ? false : item.required"
        :isEmail="item.isEmail"
        :isPhone="item.isPhone"
        :isZipCode="item.isZipCode"
        :placeholder="item.placeholder"
        :item="item"
      />
    </div>
  </div>
</template>

<script>
import inputTextField from "./formComponents/inputTextField";
import "cleave.js/dist/addons/cleave-phone.lt.js";

export default {
  props: {
    userInfo: {
      type: Object,
    },
    disableEmail: {
      type: Boolean,
      default: false,
    },
    notRequired: {
      type: Boolean,
      default: false,
    },
    shippingMethodcode: {
      type: String,
    },
  },
  components: {
    inputTextField,
  },
};
</script>

<style></style>
