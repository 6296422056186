<template>
  <div
    @click.stop.prevent="$emit('close')"
    :class="{ 'fixed bg-overlay': !notpopup }"
    class="container mx-auto flex justify-center top-0 mt-4 left-0 w-full z-50"
  >
    <div
      @click.stop.prevent
      :id="notpopup ? '' : 'call-window'"
      :class="{ 'p-24': !notpopup, 'p-8 ml-4 mr-4': notpopup }"
      class="bg-white shadow-lg relative rounded-3xl w-10/12 mx-auto mt-8 lg:mt-0"
    >
      <div class="grid grid-cols-12 p-2 md:p-10">
        <div class="col-span-12 lg:col-span-5 flex">
          <div>
            <div class="text-header font-bold w-full mb-4">
              Kontaktinė informacija
            </div>
            <userContactDetails :userInfo="userInfo" notRequired disableEmail />
          </div>
        </div>
        <div class="col-span-12 lg:col-span-7 mt-6 lg:mt-0">
          <div class="text-header font-bold">
            Pirkimų istorija
          </div>
          <div class="rounded-lg border-yellow border mt-8">
            <div
              class="grid grid-cols-5 p-4 text-extrasm font-bold bg-yellow text-center lg:text-left"
            >
              <div class="col-span-2">ID</div>
              <div class="col-span-1">Data</div>
              <div class="col-span-1">Kaina</div>
              <div class="col-span-1">Sąskaita</div>
            </div>
            <div v-if="!purchases.length" class="p-8 bold text-center">
              Tuščia
            </div>
            <div
              class="border-t border-yellow grid grid-cols-5 p-2 lg:p-4 text-extrasm text-center lg:text-left"
              :key="item"
              v-for="item of purchasesToShow"
            >
              <div class="col-span-5 md:col-span-2">
                {{ item.purchase_number }}
              </div>
              <div class="col-span-5 md:col-span-1">{{ item.created_at }}</div>
              <div class="col-span-5 md:col-span-1">{{ item.total_price }}</div>
              <div
                @click="downloadInvoice(item.purchase_number)"
                class="col-span-5 md:col-span-1 cursor-pointer text-analog"
              >
                Atsisiųsti
              </div>
            </div>
          </div>
          <div>
            <div
              @click="showAll = !showAll"
              v-if="purchases.length > 6"
              class="mt-3 text-blue-900 border-b cursor-pointer inline-block border-blue-900 font-bold text-extrasm"
            >
              Rodyti {{ showAll ? "mažiau" : "daugiau" }}
            </div>
          </div>
        </div>
      </div>
      <div
        id="bottom-row"
        :class="{ absolute: !notpopup, relative: notpopup }"
        :style="{
          boxShadow: !notpopup
            ? '1px 1px 1px 1px rgba(0, 0, 0, 0.05) inset'
            : '',
        }"
        class="bg-white pt-4 mb-6 bottom-0 left-0 w-full flex items-center justify-between pl-8 pr-8"
      >
        <div id="banks" class="flex">
          <div
            :key="logo"
            :class="{
              'ring-2 ring-yellow': selectedPayseraBank === logo.code,
            }"
            @click="selectedPayseraBank = logo.code"
            v-for="logo of bankoLogos"
            style="height:50px; width: 92px"
            class="transition cursor-pointer border-lightblue flex items-center mr-1 mt-2 text-center justify-center"
          >
            <img
              style="max-height:35px;max-width:80px"
              class="p-2"
              :src="`/assets/banks/${logo.title}.jpg`"
              alt="logo"
            />
          </div>
        </div>
        <div class="text-center">
          <mainBtn
            @on-click="save"
            text="Išsaugoti"
            icon="save"
            iconPos="right"
            textColor="green"
            bgColor="yellow"
          />
          <mainBtn
            @on-click="logout"
            text="Atsijungti"
            icon="logout"
            iconPos="right"
            textColor="yellow"
            bgColor="green"
            class="mt-4 lg:mt-0 sm:ml-4"
          />
        </div>
      </div>
    </div>
    <div
      @click="$emit('close')"
      v-html="closeIcon"
      class="absolute right-6 top-6 cursor-pointer"
    ></div>
  </div>
</template>

<script>
import userContactDetails from "./userContactDetails";
import mainBtn from "./smallComponents/mainBtn";
import { mapState } from "vuex";

export default {
  props: {
    notpopup: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    mainBtn,
    userContactDetails,
  },
  data() {
    return {
      showAll: false,
      closeIcon:
        '<svg width="26" height="26" viewBox="0 0 26 26" fill="none"> <path d="M20.3125 5.6875L5.6875 20.3125" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M20.3125 20.3125L5.6875 5.6875" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>',
    };
  },
  mounted() {
    this.fillUserFields();
    this.getPurchases();
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      purchases: (state) => state.purchases,
      userInfo: (state) => state.userInfo,
    }),
    purchasesToShow() {
      return this.purchases.slice(
        0,
        this.showAll ? this.purchases.length + 1 : 6
      );
    },
  },
  methods: {
    downloadInvoice(purchaseNumber) {
      window.open(
        this.$root.baseUrl +
          "/storage/app/media/invoices/" +
          purchaseNumber +
          ".pdf"
      );
    },
    async save() {
      const result = await this.$root.axiosInstance.post(
        `/save-user/`,
        this.userInfo
      );
      if (result.data.user) {
        this.$root.notify("Informacija išsaugota");
        window.location.reload();
      }
    },
    async logout() {
      const self = this;
      const result = await this.$root.axiosInstance.post("/logout");
      if (result.data.status) {
        self.$store.commit("setUser", {});
        self.$root.setCookie("user", "", 1);
        self.$root.setCookie("token", "", 1);
        this.$router.push("/");
        location.reload();
      }
    },
    async getPurchases() {
      const result = await this.$root.axiosInstance.get(`getPurchasesForUser`);
      this.$store.commit("updatePurchases", result.data.purchases);
    },
    fillUserFields() {
      this.userInfo.name.value = this.user.name;
      this.userInfo.email.value = this.user.email;
      this.userInfo.phone.value = this.user.phone;
      this.userInfo.city.value = this.user.city;
      this.userInfo.address.value = this.user.address;
      this.userInfo.zip.value = this.user.zip;
    },
  },
  watch: {
    user() {
      this.fillUserFields();
    },
  },
};
</script>

<style></style>
